import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import ErrorBoundary from './components/global/ErrorBoundary';
import NotFound from './components/global/NotFound';
import { ProtectedRoute, UnprotectedRoute } from './helpers/AuthRouter';
import FontLoader from './helpers/FontLoader';
import MessageProvider from './helpers/MessageProvider';
import { ModalContainer } from './libs/Modal';
import { ToastContainer } from './libs/Toast';
import AutoLogin from './pages/autologin';
import Connect from './pages/connect';
import ControlCenter from './pages/control-center';
import Intro from './pages/intro';
import Login from './pages/login';
import MissionHub from './pages/mission-hub';

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <UnprotectedRoute>
            <Intro />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/connect"
        element={
          <UnprotectedRoute>
            <Connect />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/login"
        element={
          <UnprotectedRoute>
            <Login />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/autologin"
        element={
          <UnprotectedRoute>
            <AutoLogin />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/control-center"
        element={
          <ProtectedRoute>
            <MessageProvider>
              <ControlCenter />
              <ModalContainer />
            </MessageProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/mission-hub/:id"
        element={
          <ProtectedRoute>
            <MissionHub />
            <ModalContainer />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <FontLoader />
      <ToastContainer autoClose={2000} />
      {process.env.REACT_APP_USE_ERROR_BOUNDARY === 'true' && (
        <ErrorBoundary>
          <AppRoutes />
        </ErrorBoundary>
      )}
      {process.env.REACT_APP_USE_ERROR_BOUNDARY !== 'true' && <AppRoutes />}
    </BrowserRouter>
  );
};

export default App;
