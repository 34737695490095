import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useMemo, useState } from 'react';
import {
  MdOutlineSettings,
  MdOutlineNotifications,
  MdOutlineCenterFocusStrong,
  MdOutlineChat,
  MdOutlineFeaturedVideo,
  MdOutlineRemoveRoad,
  MdOutlineHideImage,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ControlBar.module.scss';
import Notifications from './Notifications';

import actions from '@/actions';
import SettingsModal from '@/components/modals/Settings';
import Bar from '@/components/ui/Bar';
import OlMap from '@/helpers/OlMap';
import { ModalService as modal } from '@/libs/Modal';
import { ToastService as toast } from '@/libs/Toast';

const cx = classNames.bind(styles);
const defaultOptions = {
  settings: false,
  removeShoot: false,
  removeFootprint: false,
};

const ControlBar = ({ robot, options = defaultOptions }) => {
  const dispatch = useDispatch();
  const activeRobotIds = useSelector((state) => state.robot.activeRobotIds);
  const robotOptions = useSelector((state) => state.robot.options[robot.id]);
  const focusRobotId = useSelector((state) => state.robot.focusRobotId);
  const notifications = useSelector((state) => state.notification[robot.id]);
  const [isOpenNotifications, setIsOpenNotifications] = useState(false);

  // 연결 여부
  const isActive = useMemo(() => activeRobotIds.includes(robot.id), [activeRobotIds]);

  useUpdateEffect(() => {
    // 로봇 연결 해제된 경우
    if (!activeRobotIds.includes(robot.id)) {
      setIsOpenNotifications(false);
    }
  }, [activeRobotIds]);

  const toggleFocus = (e) => {
    e.stopPropagation();

    // 지도 내 해당 로봇 마커 존재하지 않는 경우
    if (!OlMap.getMap().getOverlayById(`robots/${robot.id}`)) {
      toast.error('Location has not been defined yet.');
      return;
    }

    if (focusRobotId === robot.id) {
      dispatch(actions.robot.setFocus());
    } else {
      dispatch(actions.robot.setFocus(robot.id));
    }
  };

  const toggleOption = (e) => {
    e.stopPropagation();

    const { option } = e.currentTarget.dataset;
    dispatch(actions.robot.toggleOption(robot.id, option));
  };

  const removeFootprint = (e) => {
    e.stopPropagation();

    OlMap.removeFootprint(robot.id);
  };

  const removeShoot = (e) => {
    e.stopPropagation();

    OlMap.removeShoot(robot.id);
  };

  const toggleNotifications = (e) => {
    e.stopPropagation();
    setIsOpenNotifications(!isOpenNotifications);
  };

  const openSettings = (e) => {
    e.stopPropagation();
    modal.show(SettingsModal, { robot });
  };

  if (!isActive) return;

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('side')}>
          <div className={cx(['button', { active: isOpenNotifications }])} onClick={toggleNotifications}>
            <MdOutlineNotifications size={16} title="Notifications" />
            <div className={cx('value')}>{notifications?.length ?? 0}</div>
          </div>
          {robot.isOwned && options.settings && (
            <div className={cx('button')} onClick={openSettings}>
              <MdOutlineSettings size={16} title="Settings" />
            </div>
          )}
        </div>
        <div className={cx('side')}>
          {options.removeShoot && (
            <div className={cx('button')} onClick={removeShoot}>
              <MdOutlineHideImage size={16} title="Remove Shoot" />
            </div>
          )}
          {options.removeFootprint && (
            <div className={cx('button')} onClick={removeFootprint}>
              <MdOutlineRemoveRoad size={16} title="Remove Footprint" />
            </div>
          )}
          {(options.removeShoot || options.removeFootprint) && <Bar />}
          <div className={cx(['button', { active: focusRobotId === robot.id }])} onClick={toggleFocus}>
            <MdOutlineCenterFocusStrong size={16} title="Set Focus" />
          </div>
          <div data-option="label" className={cx(['button', { active: robotOptions.label }])} onClick={toggleOption}>
            <MdOutlineChat size={16} title="Show Label" />
          </div>
          <div data-option="video" className={cx(['button', { active: robotOptions.video }])} onClick={toggleOption}>
            <MdOutlineFeaturedVideo size={16} title="Show Video" />
          </div>
        </div>
      </div>
      {isOpenNotifications && <Notifications robotId={robot.id} />}
    </div>
  );
};

export default ControlBar;
