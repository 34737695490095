import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import moment from 'moment';
import { defaults } from 'ol/control/defaults';
import { boundingExtent } from 'ol/extent';
import Feature from 'ol/Feature';
import { LineString, Point } from 'ol/geom';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import Map from 'ol/Map';
import { fromLonLat } from 'ol/proj';
import { Vector as VectorSource, BingMaps } from 'ol/source';
import { Style, Stroke, Icon } from 'ol/style';
import View from 'ol/View';
import React, { useState, useRef, useMemo } from 'react';
import { RiEditLine, RiDeleteBinLine } from 'react-icons/ri';
import { useDispatch, useStore } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './MissionList.module.scss';

import actions from '@/actions';
import arrowIcon from '@/assets/vectors/arrow.svg';
import EmptyList from '@/components/ui/EmptyList';
import ModalWrapper from '@/components/ui/Modal';
import ModalSpinner from '@/components/ui/ModalSpinner';
import { BING_API_KEY, OL_MAX_ZOOM } from '@/config';
import { getMissions, getMission, deleteMission } from '@/helpers/Requester';
import { ModalService as modal } from '@/libs/Modal';
import { ToastService as toast } from '@/libs/Toast';
import { getDistanceAlongPath } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const Modal = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [missions, setMissions] = useState();
  const [selectedMission, setSelectedMission] = useState();
  const [isLoading, setLoading] = useState(false);

  const map = useRef();
  const mapRef = useRef();
  const layer = useRef();

  useMountEffect(() => {
    setLoading(true);

    getMissions()
      .then(({ success, data }) => {
        if (success) {
          setMissions(data);
        }
      })
      .finally(() => setLoading(false));

    // Map 초기화
    map.current = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new BingMaps({ key: BING_API_KEY, imagerySet: 'Aerial' }),
        }),
      ],
      view: new View({ maxZoom: OL_MAX_ZOOM }),
      controls: defaults({ attribution: false, rotate: false, zoom: false }),
    });
  });

  useUpdateEffect(() => {
    if (!selectedMission) return;

    // 경로 정의
    const path = getPathFromJSON(selectedMission.json).map(({ lat, lng }) => fromLonLat([lng, lat]));

    // 끝점 화살표 방향 정의
    const [x1, y1] = path.at(-2);
    const [x2, y2] = path.at(-1);
    const heading = Math.atan2(y2 - y1, x2 - x1) - Math.PI / 4;

    // 기존 경로
    if (layer.current) {
      const features = layer.current.getSource().getFeatures();

      features.forEach((feature) => {
        // 경로인 경우
        if (feature.getGeometry().getType() === 'LineString') {
          feature.getGeometry().setCoordinates(path);
        }
        // 화살표인 경우
        else {
          feature.getGeometry().setCoordinates(path.at(-1));
          feature.getStyle().getImage().setRotation(-heading);
        }
      });
    }
    // 신규 경로
    else {
      const line = new Feature(new LineString(path));
      const arrow = new Feature(new Point(path.at(-1)));

      // 경로 Style 정의
      line.setStyle(
        new Style({
          stroke: new Stroke({
            color: '#41a3ff',
            width: 4,
          }),
        })
      );
      // 화살표 Style 정의
      arrow.setStyle(
        new Style({
          image: new Icon({
            src: arrowIcon,
            rotation: -heading,
          }),
        })
      );

      const source = new VectorSource({ features: [line, arrow] });
      layer.current = new VectorLayer({ source });
      map.current.addLayer(layer.current);
    }

    // 맵 중심 조정
    const extent = boundingExtent(path);
    map.current.getView().fit(extent, { padding: [40, 40, 40, 40] });
  }, [selectedMission]);

  const getPathFromJSON = (json) => {
    const path = [];
    json.forEach(({ type, data }) => {
      switch (type) {
        case 'navLand':
        case 'navLoiterToAlt':
        case 'navTakeoff':
        case 'navWaypoint':
          path.push(data.position);
          break;

        case 'navReturnToLaunch':
          path.push(json[0].data.position);
          break;

        case 'cusSurvey':
          data.positions.forEach((position) => path.push(position));
          break;

        default:
          break;
      }
    });
    return path;
  };

  const select = (e) => {
    getMission(e.currentTarget.dataset.id).then(({ success, data }) => {
      if (success) {
        setSelectedMission(data);
      }
    });
  };

  const moveToEdit = (e) => {
    e.stopPropagation();
    window.open(`/mission-hub/${e.currentTarget.dataset.id}`);
  };

  const doDelete = (e) => {
    e.stopPropagation();
    const id = e.currentTarget.dataset.id;

    deleteMission(id).then(({ success }) => {
      if (success) {
        setMissions(missions.filter((mission) => mission.id !== id));

        if (selectedMission?.id === id) {
          setSelectedMission(null);
        }
      } else {
        toast.error('Failed to delete the mission.');
      }
    });
  };

  const close = () => {
    modal.hide();
  };

  const doLoad = () => {
    if (selectedMission === undefined) {
      toast.error('No item selected.');
      return;
    }

    // Mission Hub 운용 중인 경우
    if (isMissionHub) {
      navigate(`/mission-hub/${selectedMission.id}`, { replace: true });
    }
    // Control Center 운용 중인 경우
    else {
      const missionItems = [];
      selectedMission.json.forEach((missionItem) => {
        switch (missionItem.type) {
          case 'doChangeSpeed':
          case 'navLand':
          case 'navLoiterToAlt':
          case 'navReturnToLaunch':
          case 'navTakeoff':
          case 'navWaypoint':
            missionItems.push(missionItem);
            break;

          case 'cusSurvey':
            missionItem.data.positions.forEach((position, index) => {
              missionItems.push({
                id: `${missionItem.id}/${index}`,
                type: 'navWaypoint',
                data: {
                  position: { lat: position.lat, lng: position.lng },
                  altitude: missionItem.data.altitude,
                },
              });

              // 촬영 명령 위치인 경우
              if (Object.hasOwn(position, 'shoot')) {
                // 촬영 시작점
                if (position.shoot) {
                  missionItems.push({
                    id: `${missionItem.id}/${index}/camera/start`,
                    type: 'doSetCamTriggDist',
                    data: { distance: missionItem.data.camera.interval },
                  });
                }
                // 촬영 종료점
                else {
                  missionItems.push({
                    id: `${missionItem.id}/${index}/camera/stop`,
                    type: 'doSetCamTriggDist',
                    data: { distance: 0 },
                  });
                }
              }
            });
            break;

          default:
            break;
        }
      });

      store.getState().robot.targetRobotIds.forEach((robotId) => {
        dispatch(actions.mission.load(robotId, missionItems));
      });
    }

    close();
  };

  const stats = useMemo(() => {
    if (!selectedMission) return;

    const missionItems = selectedMission.json;

    // Speed
    const speeds = missionItems.filter(({ data }) => data?.speed).map(({ data }) => data.speed);
    const maxSpeed = speeds.length > 0 ? Math.max(...speeds) : null;
    const minSpeed = speeds.length > 0 ? Math.min(...speeds) : null;

    // Altitude
    const altitudes = missionItems.filter(({ data }) => data?.altitude).map(({ data }) => data.altitude);
    const maxAltitude = Math.max(...altitudes);
    const minAltitude = Math.min(...altitudes);

    // Distance
    const path = getPathFromJSON(missionItems);
    const totalDistance = getDistanceAlongPath(path);

    return {
      maxSpeed,
      minSpeed,
      maxAltitude,
      minAltitude,
      totalDistance,
    };
  }, [selectedMission]);

  const isMissionHub = useMemo(() => {
    return location.pathname.includes('/mission-hub');
  }, []);

  return (
    <ModalWrapper>
      <div className={cx('wrapper')}>
        <div className={cx('table')}>
          <div className={cx('header')}>
            <div className={cx(['column', 'no'])}>No</div>
            <div className={cx(['column', 'name'])}>Name</div>
            <div className={cx(['column', 'time'])}>Last Update</div>
            {!isMissionHub && <div className={cx(['column', 'control'])}>Edit</div>}
            <div className={cx(['column', 'control'])}>Del.</div>
          </div>
          <div className={cx('body')}>
            {isLoading && <ModalSpinner />}
            {missions?.length === 0 && (
              <div className={cx('empty')}>
                <EmptyList />
              </div>
            )}
            {missions?.map((mission, index) => {
              const isTarget = mission.id === selectedMission?.id;
              const updatedAt = moment(mission.updatedAt).format('YYYY-MM-DD HH:mm:ss');

              return (
                <div key={index} data-id={mission.id} onClick={select} className={cx(['row', { target: isTarget }])}>
                  <div className={cx(['column', 'no'])}>{index + 1}</div>
                  <div className={cx(['column', 'name'])}>{mission.name}</div>
                  <div className={cx(['column', 'time'])}>{updatedAt}</div>
                  {!isMissionHub && (
                    <div className={cx(['column', 'control'])}>
                      <button type="button" className={cx('button')} data-id={mission.id} onClick={moveToEdit}>
                        <RiEditLine size={14} color="white" />
                      </button>
                    </div>
                  )}
                  <div className={cx(['column', 'control'])}>
                    <button type="button" className={cx('button')} data-id={mission.id} onClick={doDelete}>
                      <RiDeleteBinLine size={14} color="white" />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={cx('mapWrapper')}>
          <div ref={mapRef} className={cx(['map', { show: !!selectedMission }])} />
          {!selectedMission && (
            <div className={cx('empty')}>
              <EmptyList message="No item selected" />
            </div>
          )}
          {selectedMission && (
            <div className={cx('info')}>
              {stats.maxSpeed !== null && (
                <div className={cx('item')}>
                  <div className={cx('label')}>Max Speed</div>
                  <div className={cx('value')}>
                    {stats.maxSpeed.toFixed(1)}
                    <span>m/s</span>
                  </div>
                </div>
              )}
              {stats.minSpeed !== null && (
                <div className={cx('item')}>
                  <div className={cx('label')}>Min Speed</div>
                  <div className={cx('value')}>
                    {stats.minSpeed.toFixed(1)}
                    <span>m/s</span>
                  </div>
                </div>
              )}
              <div className={cx('item')}>
                <div className={cx('label')}>Max Altitude</div>
                <div className={cx('value')}>
                  {stats.maxAltitude.toFixed(1)}
                  <span>m</span>
                </div>
              </div>
              <div className={cx('item')}>
                <div className={cx('label')}>Min Altitude</div>
                <div className={cx('value')}>
                  {stats.minAltitude.toFixed(1)}
                  <span>m</span>
                </div>
              </div>
              <div className={cx('item')}>
                <div className={cx('label')}>Distance</div>
                <div className={cx('value')}>
                  {commaNumber((stats.totalDistance / 1000).toFixed(1))}
                  <span>km</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={cx('bottom')}>
        <button type="button" className={cx('button')} onClick={close}>
          Cancel
        </button>
        <button type="button" className={cx(['button', 'accent'])} onClick={doLoad}>
          Load
        </button>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
