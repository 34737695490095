import classNames from 'classnames/bind';
import React, { useState, useMemo } from 'react';
import {
  RiTreasureMapLine,
  RiVideoLine,
  RiHistoryLine,
  RiAccountBoxLine,
  RiProfileLine,
  RiDoorLockBoxLine,
  RiLogoutBoxRLine,
} from 'react-icons/ri';
import { useSelector } from 'react-redux';

import styles from './Sidebar.module.scss';

import ChangePasswordModal from '@/components/modals/ChangePassword';
import LogoutModal from '@/components/modals/Logout';
import ModifyUserInfoModal from '@/components/modals/ModifyUserInfo';
import OperationHistoryModal from '@/components/modals/OperationHistory';
import { isBrowser, isElectron } from '@/environment';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const Sidebar = () => {
  const user = useSelector((state) => state.user);
  const [activeMenu, setActiveMenu] = useState();

  const showMyInfo = useMemo(() => activeMenu === 'myInfo', [activeMenu]);

  const toggle = (selectedMenu) => {
    activeMenu === selectedMenu ? setActiveMenu() : setActiveMenu(selectedMenu);
  };

  // Mission Hub
  const openMissionHub = () => {
    if (isBrowser) {
      window.open('/mission-hub/new');
    } else if (isElectron) {
      window.electron.ipcRenderer.send('openMissionHub');
    }
  };

  const showOperationHistory = () => {
    setActiveMenu();
    modal.show(OperationHistoryModal);
  };

  // SmatiiView
  const openSmatiiView = () => {
    window.open('https://gimcheon.smatii.com/');
  };

  // 비밀번호변경
  const showChangePassword = () => {
    setActiveMenu();
    modal.show(ChangePasswordModal);
  };

  // 유저정보수정
  const showModifyUserInfo = () => {
    setActiveMenu();
    modal.show(ModifyUserInfoModal);
  };

  // 로그아웃
  const doLogout = () => {
    setActiveMenu();
    modal.show(LogoutModal);
  };

  return (
    <div className={cx('container')}>
      <div className={cx('logo')}>
        <img src={require('@/assets/images/logo.png')} width="31" alt="M1UCS" />
      </div>
      <div className={cx('wrapper')}>
        <div className={cx(['menus', 'top'])}>
          <div onClick={openMissionHub} className={cx('menu')}>
            <RiTreasureMapLine size={28} color="white" />
            <div className={cx('name')}>Mission Hub</div>
          </div>
          {isBrowser && process.env.REACT_APP_USE_HISTORY === 'true' && (
            <div onClick={showOperationHistory} className={cx('menu')}>
              <RiHistoryLine size={28} color="white" />
              <div className={cx('name')}>Operation</div>
            </div>
          )}
          {process.env.REACT_APP_USE_SMATIIVIEW_LINK === 'true' && (
            <div onClick={openSmatiiView} className={cx('menu')}>
              <RiVideoLine size={28} color="white" />
              <div className={cx('name')}>G-Viewer</div>
            </div>
          )}
        </div>
        <div className={cx(['menus', 'bottom'])}>
          {isBrowser && user && (
            <div onClick={() => toggle('myInfo')} className={cx(['menu', { pressed: showMyInfo }])}>
              <RiAccountBoxLine size={28} color="white" />
              <div className={cx('name')}>My Info.</div>
              <div className={cx(['panel', { show: showMyInfo }])}>
                <div className={cx('info')}>
                  <div className={cx('value1')}>{user.name}</div>
                  <div className={cx('value2')}>{user.email}</div>
                </div>
                <div className={cx('menus')}>
                  <div onClick={showModifyUserInfo} className={cx('menu')}>
                    <RiProfileLine size={14} color="white" />
                    <div className={cx('menuText')}>Modify Information</div>
                  </div>
                  <div onClick={showChangePassword} className={cx('menu')}>
                    <RiDoorLockBoxLine size={14} color="white" />
                    <div className={cx('menuText')}>Change Password</div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div onClick={doLogout} className={cx('menu')}>
            <RiLogoutBoxRLine size={28} color="white" />
            <div className={cx('name')}>Logout</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
