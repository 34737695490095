import useMountEffect from '@restart/hooks/useMountEffect';
import axios from 'axios';
import classNames from 'classnames/bind';
import React, { useState, useRef, useLayoutEffect } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';

import styles from './Panel.module.scss';
import ViewFinder from './ViewFinder';

import actions from '@/actions';

const cx = classNames.bind(styles);

const Panel = ({ data: robot }) => {
  const dispatch = useDispatch();
  const [videos, setVideos] = useState();
  const containerRef = useRef();
  const isDragging = useRef(false);
  const offset = useRef({ x: 0, y: 0 });

  useLayoutEffect(() => {
    const dashboard = document.getElementById(`dashboard/${robot.id}`);
    const { top, right } = dashboard.getBoundingClientRect();

    const nextTop = Math.max(0, top); // Dashboard가 화면 상단을 벗어난 경우
    const nextLeft = right + 8; // Margin (8)

    containerRef.current.style.top = `${nextTop}px`;
    containerRef.current.style.left = `${nextLeft}px`;
  }, []);

  useMountEffect(() => {
    axios.get(`${process.env.REACT_APP_KURENTO_URL}/robots/${robot.id}/cameras`).then((result) => {
      const { success, data } = result.data;
      setVideos(success ? data : []);
    });
  });

  const startDrag = (e) => {
    isDragging.current = true;
    containerRef.current.style.pointerEvents = 'none';

    const { top, left } = containerRef.current.getBoundingClientRect();
    offset.current.x = e.clientX - left;
    offset.current.y = e.clientY - top;

    document.addEventListener('mousemove', onDrag);
    document.addEventListener('mouseup', endDrag);
  };

  const onDrag = (e) => {
    if (!isDragging.current) return;

    let nextLeft = e.clientX - offset.current.x;
    let nextTop = e.clientY - offset.current.y;

    const maxLeft = window.innerWidth - containerRef.current.offsetWidth;
    const maxTop = window.innerHeight - containerRef.current.offsetHeight - 40; // Bottom Bar's Height (40)

    // 수평 이동 제한
    nextLeft = Math.max(72, Math.min(nextLeft, maxLeft)); // Sidebar's Width (72)
    // 수직 이동 제한
    nextTop = Math.max(0, Math.min(nextTop, maxTop));

    containerRef.current.style.left = `${nextLeft}px`;
    containerRef.current.style.top = `${nextTop}px`;
  };

  const endDrag = () => {
    isDragging.current = false;
    containerRef.current.style.pointerEvents = 'auto';

    document.removeEventListener('mousemove', onDrag);
    document.removeEventListener('mouseup', endDrag);
  };

  const close = () => {
    dispatch(actions.robot.toggleOption(robot.id, 'video'));
  };

  return (
    <div ref={containerRef} className={cx('container')}>
      <div className={cx('color')} style={{ backgroundColor: robot.color }} />
      <div className={cx('header')}>
        <div className={cx('handle')} onMouseDown={startDrag} />
        <div className={cx('close')} onClick={close}>
          <RiCloseLine size={16} color="white" />
        </div>
      </div>
      {robot.id !== 'b3bcd516-c06b-47cc-97fd-828c07593c7f' && (
        <div className={cx('body')}>
          {videos?.length === 0 && (
            <div className={cx('empty')}>
              <div className={cx('title')}>No Video Channels</div>
              <div className={cx('message')}>
                If the robot has a cameras,
                <br />
                Please wait for the reconnection.
              </div>
            </div>
          )}
          {videos?.map((video) => (
            <ViewFinder key={video.deviceId} robotId={robot.id} video={video} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Panel;
